define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/fx!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (lang, declare, baseFx, aspect) {
  // module:
  //		dojo/fx/Toggler

  return declare("dojo.fx.Toggler", null, {
    // summary:
    //		A simple `dojo.Animation` toggler API.
    // description:
    //		class constructor for an animation toggler. It accepts a packed
    //		set of arguments about what type of animation to use in each
    //		direction, duration, etc. All available members are mixed into
    //		these animations from the constructor (for example, `node`,
    //		`showDuration`, `hideDuration`).
    // example:
    //	|	var t = new dojo/fx/Toggler({
    //	|		node: "nodeId",
    //	|		showDuration: 500,
    //	|		// hideDuration will default to "200"
    //	|		showFunc: dojo/fx/wipeIn,
    //	|		// hideFunc will default to "fadeOut"
    //	|	});
    //	|	t.show(100); // delay showing for 100ms
    //	|	// ...time passes...
    //	|	t.hide();

    // node: DomNode
    //		the node to target for the showing and hiding animations
    node: null,
    // showFunc: Function
    //		The function that returns the `dojo.Animation` to show the node
    showFunc: baseFx.fadeIn,
    // hideFunc: Function
    //		The function that returns the `dojo.Animation` to hide the node
    hideFunc: baseFx.fadeOut,
    // showDuration:
    //		Time in milliseconds to run the show Animation
    showDuration: 200,
    // hideDuration:
    //		Time in milliseconds to run the hide Animation
    hideDuration: 200,
    // FIXME: need a policy for where the toggler should "be" the next
    // time show/hide are called if we're stopped somewhere in the
    // middle.
    // FIXME: also would be nice to specify individual showArgs/hideArgs mixed into
    // each animation individually.
    // FIXME: also would be nice to have events from the animations exposed/bridged

    /*=====
    _showArgs: null,
    _showAnim: null,
    	_hideArgs: null,
    _hideAnim: null,
    	_isShowing: false,
    _isHiding: false,
    =====*/

    constructor: function (args) {
      var _t = this;
      lang.mixin(_t, args);
      _t.node = args.node;
      _t._showArgs = lang.mixin({}, args);
      _t._showArgs.node = _t.node;
      _t._showArgs.duration = _t.showDuration;
      _t.showAnim = _t.showFunc(_t._showArgs);
      _t._hideArgs = lang.mixin({}, args);
      _t._hideArgs.node = _t.node;
      _t._hideArgs.duration = _t.hideDuration;
      _t.hideAnim = _t.hideFunc(_t._hideArgs);
      aspect.after(_t.showAnim, "beforeBegin", lang.hitch(_t.hideAnim, "stop", true), true);
      aspect.after(_t.hideAnim, "beforeBegin", lang.hitch(_t.showAnim, "stop", true), true);
    },
    show: function (delay) {
      // summary:
      //		Toggle the node to showing
      // delay: Integer?
      //		Amount of time to stall playing the show animation
      return this.showAnim.play(delay || 0);
    },
    hide: function (delay) {
      // summary:
      //		Toggle the node to hidden
      // delay: Integer?
      //		Amount of time to stall playing the hide animation
      return this.hideAnim.play(delay || 0);
    }
  });
});