/**
 * @module mojo/page/data
 *
 * AMD module for retrieving and handling stringified json from the dom. This can be useful
 * if we are needing to pass data from the backend to an easily accessible. This is intended
 * to be a read-only operation and does not have any affordances for freezing objects, storage
 * in memory or handling sensitive data.
 */
define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dom) {
  return {
    /**
     * Grab a reference to the dom node that has the data.
     *
     * @param {string} domNodeId - ID of a domNode to target
     *
     * @private
     * @return {Element} - Target node
     */
    _getNodeById: function (domNodeId) {
      return dom.byId(domNodeId);
    },
    /**
     * Given a blob of stringified JSON, parse and return it handling for invalid JSON.
     *
     * @param {string} stringifiedJsonBlob '{"result":true, "count":42}'
     *
     * @returns {{}} a parsed JSON object for use
     * @private
     */
    _parseAndValidateBody: function (stringifiedJsonBlob) {
      var json = {};
      try {
        json = JSON.parse(stringifiedJsonBlob);
      } catch (error) {
        // This is likely incorrectly formatted JSON. Let Bugsnag know.
        console.error(error);
      }
      return json;
    },
    /**
     * Quick heuristic check that the node exists and that we can access textContent.
     * There are additional validity checks we could possibly perform.
     *
     * @param {Element} node — The target data node
     * @returns {bool} Whether we think the node and contents are valid prior to parsing
     * @private
     */
    _isValid: function (node) {
      return node && node.textContent;
    },
    /**
     * @param {string} domNodeId - ID of a domNode to target
     * @returns {bool} Should we attempt to load/parse?
     */
    isAvailable: function (domNodeId) {
      var node = this._getNodeById(domNodeId);
      return this._isValid(node);
    },
    /**
     * Given a dom node, validate and parse
     *
     * @param {string} domNodeId - ID of a domNode to target
     * @returns {{}} Object ready for use
     */
    getAndParse: function (domNodeId) {
      if (typeof domNodeId === "undefined") {
        throw new Error("Dom ID required");
      }

      // Grab a reference to the node but make sure not to mutate it.
      var node = this._getNodeById(domNodeId);

      // Quick safety check
      if (this._isValid(node)) {
        return this._parseAndValidateBody(node.textContent);
      }
      return {};
    }
  };
});