define(["./focus", "./_WidgetBase", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/ae1b7dba924ff9404b215b885ac233bb77b8177f/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module" // lang.extend
], function (focus, _WidgetBase, declare, lang) {
  // module:
  //		dijit/_FocusMixin

  // We don't know where _FocusMixin will occur in the inheritance chain, but we need the _onFocus()/_onBlur() below
  // to be last in the inheritance chain, so mixin to _WidgetBase.
  lang.extend(_WidgetBase, {
    // focused: [readonly] Boolean
    //		This widget or a widget it contains has focus, or is "active" because
    //		it was recently clicked.
    focused: false,
    onFocus: function () {
      // summary:
      //		Called when the widget becomes "active" because
      //		it or a widget inside of it either has focus, or has recently
      //		been clicked.
      // tags:
      //		callback
    },
    onBlur: function () {
      // summary:
      //		Called when the widget stops being "active" because
      //		focus moved to something outside of it, or the user
      //		clicked somewhere outside of it, or the widget was
      //		hidden.
      // tags:
      //		callback
    },
    _onFocus: function () {
      // summary:
      //		This is where widgets do processing for when they are active,
      //		such as changing CSS classes.  See onFocus() for more details.
      // tags:
      //		protected
      this.onFocus();
    },
    _onBlur: function () {
      // summary:
      //		This is where widgets do processing for when they stop being active,
      //		such as changing CSS classes.  See onBlur() for more details.
      // tags:
      //		protected
      this.onBlur();
    }
  });
  return declare("dijit._FocusMixin", null, {
    // summary:
    //		Mixin to widget to provide _onFocus() and _onBlur() methods that
    //		fire when a widget or its descendants get/lose focus

    // flag that I want _onFocus()/_onBlur() notifications from focus manager
    _focusManager: focus
  });
});